function setOrgEvent(targetElem) {
  targetElem.querySelectorAll('.org-accordion-headers').forEach(elem => {
    elem.addEventListener('click', function(e) {
      const targetHeader = e.currentTarget;
      if (targetHeader.parentElement.querySelector('.organization-card')) {
        return;
      }
      jqXHR = $.ajax({
        async: true,
        url: `/organizations?parent_code=${targetHeader.getAttribute('data-ns-org-code')}`,
        type: 'GET',
        dataType: 'html',
        cache: false,
      });
      jqXHR.done(function(data, stat, xhr) {
        let ab = targetHeader.parentElement.querySelector('.accordion-body');
        ab.insertAdjacentHTML("beforeend", xhr.responseText);
        setOrgEvent(ab);
      });
    });
  });
}

window.addEventListener("load", () => {
  var orgs = document.getElementById('organizations');
  if (orgs) {
    setOrgEvent(orgs);
  }
});