// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import { Tooltip } from 'bootstrap';
import '../stylesheets/application';
import '../channels/organization'

window.addEventListener("load", () => {
  $(".top-info-alert").first().hide().fadeIn(200).delay(4000).fadeOut(1000, function () {
    $(this).remove();
  });
});

// ツールチップの初期化。参考はこちら
//   https://stackoverflow.com/questions/64777987/bootstrap-5-tooltip-popover-and-toasts-not-working-in-ruby-on-rails-6
//   https://getbootstrap.jp/docs/5.0/components/tooltips/#example-enable-tooltips-everywhere
document.addEventListener("DOMContentLoaded", function(event) {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
});
